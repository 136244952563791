import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { takeUntil } from 'rxjs/operators';
import { AnnouncementService } from '@appShared/services/announcement.service';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent extends Unsubscriber implements OnInit, OnDestroy {
  @Input() isWithoutIndents = false;

  @HostBinding('class.with-announcement')
  withAnnouncement: boolean;

  constructor(private announcementService: AnnouncementService) {
    super();
  }

  ngOnInit(): void {
    this.announcementService
      .getShowAnnouncementLayout$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((value) => (this.withAnnouncement = value && !this.isWithoutIndents));
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
