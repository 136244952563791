import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { FormsHelperService } from '@appCore/services/form-helper/form-helper.service';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { getDocumentPackage } from '@appDocuments/core/store/document-package';
import { DocumentPageMode } from '@appDocuments/shared/enums/document-page-mode.enum';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { EmployeeApiService } from '@appShared/api/employee/employee.api.service';
import { GENERAL_TEXT_ERRORS } from '@appShared/const/global-text-errors.const';
import { employeeFullName } from '@appShared/pipes/employee-full-name.pipe';
import { accessDocumentPackageByType } from '@appShared/validators/project-types-group-role.validator';
import { DictionaryWithShortNameModel } from '@models/base/dictionary-with-short-name.model';
import { DictionaryModel } from '@models/base/dictionary.model';
import { OrganizationModel } from '@models/base/organization.model';
import { ProjectTypeModel } from '@models/document-package/project-type.model';
import { EmployeeBaseModel } from '@models/employee/employee-base.model';
import { EmployeeWithFullNameModel } from '@models/employee/employee-with-full-name.model';
import moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, merge, Observable, of, timer } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-new-employee-sub-form',
  templateUrl: './new-employee-sub-form.component.html',
  styleUrls: ['./new-employee-sub-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewEmployeeSubFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NewEmployeeSubFormComponent),
      multi: true,
    },
  ],
})
export class NewEmployeeSubFormComponent
  extends Unsubscriber
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, AfterViewInit, Validator
{
  @Input()
  isBlackTheme: boolean;
  @Input()
  organization: DictionaryWithShortNameModel;
  @Input()
  isDisabledOrganization: boolean;
  // Подумать над валидацией без входного параметра */
  @Input()
  isRequired: boolean;
  @Input()
  attentionText: string;
  @Input()
  pageMode: DocumentPageMode;
  /** Проверка на возможность предзаполнения формы */
  @Input()
  isFilledForm: boolean;
  @Input()
  projectType: ProjectTypeModel;
  @Input()
  manualProjectType = false;
  @Input()
  governmentRoleCheck = false;
  @Input()
  considerationDate: string;

  // Параметр говорит о том, что форма необязательна к заполнению
  @Input()
  isNotRequired = false;
  /** переопределение плейсхолдеров для всех трех селектов */
  @Input()
  placeholder: string;
  /** должен быть выбран исполнитель */
  @Input()
  mustRequireEmployee = false;
  @Input()
  orderType: number;
  @Input()
  canShowExecutor = true;
  /** Возвращать организацию и должность, даже если не выбран исполнитель */
  @Input()
  returnOrganizationAndPosition = false;

  @Output() onOrganization: EventEmitter<OrganizationModel> = new EventEmitter<OrganizationModel>();

  @ViewChild('container', { static: true })
  containerElement: ElementRef;

  @ViewChildren('executor') viewChildren: QueryList<ElementRef>;

  public employeeForm: UntypedFormGroup;

  public organizations$: Observable<DictionaryModel[]>;
  public positions$: Observable<DictionaryModel[]>;
  public employeeList$: Observable<EmployeeBaseModel[]>;
  public assignedExecutors: EmployeeBaseModel[];
  public employeeFilterControl = new UntypedFormControl();

  public isWorkStatusMenuOpened = false;
  public isOutOfSightEmployeeContainer = false;
  public readonly noResultsFromRequest = GENERAL_TEXT_ERRORS.noResultsFromRequest;

  private updatePosition: boolean;
  private disableUpdate = false;

  private thisControl: UntypedFormControl;

  private filterChanges$ = new BehaviorSubject<string>('');
  private filterValue = '';
  private updatePositions = new BehaviorSubject<string>('');
  private canShowExecutor$ = new BehaviorSubject<boolean>(false);
  private orderType$ = new BehaviorSubject<number>(null);

  public onChange = (employee: EmployeeBaseModel) => {};

  constructor(
    public formBuilder: UntypedFormBuilder,
    public dictionaryApi: DictionaryApiService,
    public employeeApi: EmployeeApiService,
    public injector: Injector,
    public cdr: ChangeDetectorRef,
    public redux: ReduxService,
    public formsHelper: FormsHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.canShowExecutor$.next(this.canShowExecutor);
    this.employeeForm = this.initForm(this.isNotRequired);
    this.organizations$ = this.getOrganizationsList();
    this.positions$ = this.getPositionsList();
    this.getEmployeeList();
    this.getFavoriteEmployeeList();

    if (this.isRequired) {
      this.organizationControl.setValidators([Validators.required]);
      this.organizationControl.markAsTouched();
    }
    this.setEmployeeValidator();
    if (!this.isFilledForm) {
      this.resetEmployeeOnOrganizationChange();
      this.resetEmployeeOnPositionChange();
    }
    this.updatePositionAndOrganizationOnEmployeeChange();
    this.callOnChangeOnEmployeeSelect();
    if (this.isDisabledOrganization) {
      this.organizationControl.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectType && this.employeeForm) {
      this.setEmployeeValidator();
    }

    if (changes.canShowExecutor?.currentValue !== changes.canShowExecutor?.previousValue) {
      this.canShowExecutor$.next(changes.canShowExecutor?.currentValue);
    }

    if (changes.orderType?.currentValue !== changes.orderType?.previousValue) {
      this.orderType$.next(changes.orderType?.currentValue);
    }

    const notRequiredCurrent = changes.isNotRequired?.currentValue;
    const notRequiredPrevious = changes.isNotRequired?.previousValue;

    if (this.employeeForm && notRequiredCurrent !== notRequiredPrevious && !notRequiredCurrent) {
      this.employeeControl.setErrors({ err: true });
      this.positionControl.setErrors({ err: true });
    }
  }

  ngAfterViewInit(): void {
    this.setOrganization();
    this.getThisControl();
    if (!this.projectType && !this.manualProjectType) {
      this.takeProjectTypeFromStore();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  private organizationHandle(organization: OrganizationModel): void {
    this.onOrganization.emit(organization);
  }

  // надо пересмотреть способ получения типа пакета внутри этого компонента */
  /* если тип проекта не был передан извне, то извлекаем его из загруженного ПД */
  private takeProjectTypeFromStore(): void {
    this.redux
      .selectStore(getDocumentPackage)
      .pipe(
        filter((documentPackage) => !!documentPackage),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((documentPackage) => {
        this.projectType = documentPackage.projectType;
        this.setEmployeeValidator();
        this.employeeControl.updateValueAndValidity();

        // обновляем состояние контрола извне
        this.thisControl?.updateValueAndValidity();
        this.cdr.markForCheck();
      });
  }

  public get organizationControl(): AbstractControl {
    return this.employeeForm.get('organization');
  }

  public get positionControl(): AbstractControl {
    return this.employeeForm.get('position');
  }

  public get employeeControl(): AbstractControl {
    return this.employeeForm.get('employee');
  }

  /** Инициализация формы добавления работника */
  public initForm(notRequired: boolean): UntypedFormGroup {
    const validators = notRequired ? [] : [Validators.required];
    return this.formBuilder.group({
      organization: null,
      position: [null, validators],
      employee: [null, validators],
    });
  }

  private setOrganization(): void {
    if (this.organization) {
      this.organizationControl.setValue(this.organization);
    }
  }
  /** Установка значений форм при ините */
  public setFormValue(employee: EmployeeWithFullNameModel): void {
    if (!employee) {
      this.setEmployeeValidator();
      return;
    }

    if (!employee.fullName) {
      employee = { ...employee, fullName: employeeFullName(employee) };
    }

    const formValue = {
      organization: employee.organization,
      position: employee.jobPosition,
      employee: employee.id ? employee : null,
    };
    this.isWorkStatusMenuOpened = false;
    this.employeeForm.patchValue(formValue);
  }

  /** Сброс работника при смене организации */
  public resetEmployeeOnOrganizationChange(): void {
    this.organizationControl.valueChanges
      .pipe(
        filter((i) => !this.disableUpdate),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((organization) => {
        this.organizationHandle(organization);
        this.positionControl.enable();
        this.positionControl.reset();
        this.positionControl.markAsTouched();
        this.employeeControl.enable();
        this.employeeControl.reset();
        this.employeeControl.markAsTouched();
        this.filterValue = null;
      });
  }

  /** Сброс работника при смене должности */
  private resetEmployeeOnPositionChange(): void {
    this.positionControl.valueChanges
      .pipe(
        filter((i) => !this.disableUpdate),
        filter((i) => !this.updatePosition),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((i) => {
        this.updatePositions.next(this.filterValue);
        this.filterValue = null;
        this.employeeControl.enable();
        this.employeeControl.reset();
        this.employeeControl.markAsTouched();
      });
  }

  private updatePositionAndOrganizationOnEmployeeChange(): void {
    this.employeeControl.valueChanges
      .pipe(
        filter((i) => !this.disableUpdate),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((employee) => {
        if (employee) {
          this.updatePosition = true;
          const position = employee.jobPosition;
          this.positionControl.setValue(position, { emitEvent: false });
          this.organizationControl.setValue(employee.organization, { emitEvent: false });
          this.updatePosition = false;
          this.organizationHandle(this.organizationControl.value);
        } else {
          this.positionControl.setValue(this.positionControl?.value);
        }
      });
  }

  /** Получить список сотрудников */
  public getOrganizationsList(): Observable<DictionaryModel[]> {
    return this.canShowExecutor$.pipe(
      filter((value) => value),
      switchMap(() => this.dictionaryApi.getOrganizationsAll()),
      map((organizationList) => organizationList.sort(this.propComparator('name')))
    );
  }

  private propComparator(propName) {
    return (a, b) => {
      if (a[propName] < b[propName]) {
        return -1;
      }
      if (a[propName] > b[propName]) {
        return 1;
      }
      return 0;
    };
  }

  /** Получение списка должностей */
  private getPositionsList(): Observable<DictionaryModel[]> {
    const filterChanges$ = this.filterChanges$.pipe(debounce(() => timer(1000)));

    return merge(
      this.organizationControl.valueChanges,
      this.updatePositions,
      filterChanges$,
      this.canShowExecutor$
    ).pipe(
      debounceTime(0),
      filter(() => (!!this.organizationControl?.value || !!this.filterValue) && this.canShowExecutor),
      switchMap(() => this.dictionaryApi.getEmployeePositions(this.organizationControl?.value?.id, this.filterValue)),
      map((positionList) => positionList.sort(this.propComparator('name')))
    );
  }

  private getHistoryDateTime(): string {
    const actualDate = moment().tz('Europe/Moscow').format('YYYY-MM-DD');
    const isConsiderationDateAfter = this.considerationDate && moment(this.considerationDate).isAfter(actualDate);
    if (!this.considerationDate || isConsiderationDateAfter) {
      return '';
    }

    return moment(this.considerationDate).utc(true).toISOString();
  }

  /** Получение списка сотрудников */
  private getEmployeeList(): void {
    const organizationChange$ = this.organizationControl.valueChanges.pipe(distinctUntilChanged());
    const positionChange$ = this.positionControl.valueChanges.pipe(distinctUntilChanged());
    const employeeChange$ = this.employeeFilterControl.valueChanges.pipe(distinctUntilChanged());
    const filterChanges$ = this.filterChanges$.pipe(debounce(() => timer(1000)));

    const positionOrganisationEmployeeChanges$ = merge(
      organizationChange$,
      positionChange$,
      employeeChange$,
      filterChanges$
    );

    positionOrganisationEmployeeChanges$
      .pipe(
        filter(() => this.canShowExecutor),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => {
        const employeeFilterData = this.employeeFilterControl.value || '';
        const organizationId = this.organizationControl.value ? this.organizationControl.value.id : '';
        const positionId = this.positionControl.value ? this.positionControl.value.id : '';

        if (!employeeFilterData && !organizationId && !positionId && !this.filterValue) {
          this.employeeList$ = of(null);
          return;
        }

        const historyDateTime = this.getHistoryDateTime();

        this.employeeList$ = this.employeeApi
          .getEmployeeList(organizationId, positionId, employeeFilterData, this.filterValue, historyDateTime)
          .pipe(
            map((employeeList) =>
              employeeList
                .map((i) => ({
                  ...i,
                  fullName: employeeFullName(i),
                  organizationName: this.getOrganizationName(i),
                }))
                .sort(this.propComparator('fullName'))
            )
          );
      });
  }

  public onTouched: () => void = () => {};

  writeValue(employee: EmployeeWithFullNameModel): void {
    if (!employee) {
      this.employeeForm.reset();
    }
    this.setFormValue(employee);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disableUpdate = true;
    if (isDisabled) {
      this.employeeForm.disable();
    } else if (this.organizationControl.value || this.pageMode === DocumentPageMode.edit) {
      this.employeeForm.enable();
    } else {
      this.organizationControl.enable();
      this.positionControl.enable();
      this.employeeControl.enable();
    }
    this.disableUpdate = false;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.employeeForm.valid ? null : { invalidForm: { valid: false, message: 'fields are invalid' } };
  }

  public getOrganizationName(employee: EmployeeBaseModel): string {
    if (!employee || !employee.organization) {
      return null;
    }

    return employee.organization.shortName || employee.organization.name;
  }

  private callOnChangeOnEmployeeSelect(): void {
    combineLatest([
      this.employeeControl.valueChanges,
      this.organizationControl.valueChanges,
      this.positionControl.valueChanges,
    ])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([employeeControl]) => {
        this.onChange(
          employeeControl || this.returnOrganizationAndPosition
            ? {
                ...employeeControl,
                jobPosition: this.positionControl.getRawValue(),
                organization: this.organizationControl.getRawValue(),
              }
            : null
        );
      });
  }

  // Подумать над валидацией без получения контрола
  /** получить ссылку на этот контрол */
  private getThisControl(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      this.thisControl = ngControl.control as UntypedFormControl;
      setTimeout(() => {
        this.addValidatorRequiredForOrganizationSelector();
      });
    }
  }

  /** добавить валидатор для оганизации, если на этом контроле сработал валидатор */
  private addValidatorRequiredForOrganizationSelector(): void {
    if (this.thisControl.errors && this.thisControl.errors.required) {
      const selectorValidator: ValidatorFn[] = [Validators.required];
      this.organizationControl.setValidators(selectorValidator);
      this.organizationControl.updateValueAndValidity();
      this.organizationControl.markAsTouched();
    }
  }

  /** Удаляет дубликаты объектов по ID */
  public deleteDuplicatedByID(listWithID: DictionaryModel[]): DictionaryModel[] {
    return listWithID.filter((o1, i1) => !listWithID.some((o2, i2) => o1.id === o2.id && i2 < i1));
  }

  public changeFilter(event: string) {
    this.filterChanges$.next(event);
    this.filterValue = event;
  }

  private setEmployeeValidator(): void {
    this.formsHelper.setValidators(
      this.employeeControl,
      [
        accessDocumentPackageByType(
          this.projectType?.projectGroupType,
          this.governmentRoleCheck,
          this.mustRequireEmployee
        ),
      ],
      false
    );
  }

  private getFavoriteEmployeeList(): void {
    combineLatest([this.canShowExecutor$, this.orderType$])
      .pipe(
        filter(([canShowExecutor, orderType]) => canShowExecutor && !!orderType),
        switchMap(() => this.employeeApi.getFavoriteEmployeeList(this.orderType)),
        map((employeeList) =>
          employeeList.map((i) => ({
            ...i.executor,
            fullName: employeeFullName(i.executor),
            organizationName: this.getOrganizationName(i.executor),
          }))
        )
      )
      .subscribe((value) => {
        this.assignedExecutors = value;
        this.isOutContainer();
      });
  }

  private isOutContainer(): void {
    this.cdr.detectChanges();
    const containerWidth = this.containerElement?.nativeElement?.offsetWidth;
    let remainingPlaceInLine = containerWidth;
    const stringCount = this.viewChildren.reduce((accumulator, el) => {
      if (remainingPlaceInLine < el.nativeElement.offsetWidth) {
        remainingPlaceInLine = containerWidth - el.nativeElement.offsetWidth - 5;
        return accumulator + 1;
      }
      remainingPlaceInLine = remainingPlaceInLine - el.nativeElement.offsetWidth - 5;

      return accumulator;
    }, 1);

    this.isOutOfSightEmployeeContainer = stringCount > 3;

    if (this.isOutOfSightEmployeeContainer) {
      this.cdr.detectChanges();
    }
  }
}
