import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ControlBaseComponent } from '@appShared/components/controls/control-base/control-base.component';
import { InputCalendarAnimations } from '@appShared/components/controls/input-calendar/components/input-calendar-base-component/input-calendar-window.animations';
import { MIN_YEAR } from '@appShared/components/controls/input-calendar/const/min-year.const';
import { CalendarWindowStates } from '@appShared/components/controls/input-calendar/enums/calendar-window-states.enum';
import {
  DisplayPositionModel,
  UserInputStreamModel,
} from '@appShared/components/controls/input-calendar/models/date-picker.model';
import { HighlightedDateModel } from '@appShared/components/controls/input-calendar/models/highlighted-date.model';
import { DomEventService } from '@appShared/services/dom-event.service';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  template: '',
  animations: InputCalendarAnimations,
})
export class InputCalendarBaseComponent
  extends ControlBaseComponent
  implements ControlValueAccessor, OnDestroy, OnChanges, AfterViewInit
{
  /** Даты для выделения */
  @Input()
  highlightedDates: HighlightedDateModel[];

  /** контрол выдает значение с часовым поясом клиента */
  @Input()
  isShowLocalTimeZone = true;

  /** максимальная дата для выбора в календаре (включая эту дату) */
  @Input()
  public maxDate: moment.Moment;

  /** минимальная дата для выбора в календаре (включая эту дату) */
  @Input()
  public minDate: moment.Moment;

  /** нажатие enter */
  @Output()
  onEnter: EventEmitter<void> = new EventEmitter();

  @Output()
  changeCalendarPosition = new EventEmitter<string>();

  @Output()
  onResetDate = new EventEmitter<void>();

  @HostListener('window:resize')
  onResize() {
    this.hideCalendar();
  }
  @HostListener('window:keydown', ['$event'])
  keyDown(event) {
    if (this.calendarState$.getValue() === CalendarWindowStates.open) {
      event.preventDefault();
    }
  }

  public inputValue$ = new BehaviorSubject('');
  public dateFormat = 'DD.MM.YYYY';
  public disabled: boolean;
  public calendarDisplayPosition: DisplayPositionModel;
  public calendarState$ = new BehaviorSubject(CalendarWindowStates.close);
  public isShowOptions = false;

  protected userInputStream: Subject<UserInputStreamModel>;

  /** предварительное объявление функций NG_VALUE_ACCESSOR */
  public onChange = (date: string) => {};
  public onTouched = () => {};

  constructor(private domEventService: DomEventService, protected elementRef: ElementRef) {
    super();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    /** ловить изменения пограничных дат */
    if (changes.minDate && !moment(changes.minDate.currentValue).isSame(changes.minDate.previousValue, 'day')) {
      this.userInputStream.next(new UserInputStreamModel(this.inputValue$.getValue()));
    }

    if (changes.maxDate && !moment(changes.maxDate.currentValue).isSame(changes.maxDate.previousValue, 'day')) {
      this.userInputStream.next(new UserInputStreamModel(this.inputValue$.getValue()));
    }
  }

  ngAfterViewInit() {
    this.firstIteration();
    this.closeCalendarWhenClickedOutside();
  }

  /** поймать передаваемое значение через NG_VALUE_ACCESSOR */
  public writeValue(date: moment.Moment | Date | string): void {
    const incomingDate = moment(date);
    if (!incomingDate.isValid()) {
      this.resetDate();
      return;
    }

    this.userInputStream.next(new UserInputStreamModel(incomingDate.format(this.dateFormat)));
  }

  /** определить функцию onChange из NG_VALUE_ACCESSOR */
  public registerOnChange(fn: (date: string) => void): void {
    this.onChange = fn;
  }

  /** определить функцию onTouched из NG_VALUE_ACCESSOR */
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /** поймать отключение формы через NG_VALUE_ACCESSOR */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (!isDisabled) {
      setTimeout(() => {
        this.userInputStream.next(new UserInputStreamModel(this.inputValue$.getValue()));
      });
    } else {
      this.hideCalendar();
    }
  }

  /** Сбрасывает дату */
  public resetDate(ev?: Event): void {
    ev?.stopPropagation();
    this.userInputStream.next(new UserInputStreamModel(''));
    this.onResetDate.emit();
  }

  /** Происходит при выборе даты */
  public onDateSelected(date: moment.Moment): void {
    const datePosition = date.isValid() ? date : moment();
    this.userInputStream.next(new UserInputStreamModel(date.format(this.dateFormat), datePosition));
  }

  /** Происходит на переключение месяца в календаре */
  public onChangeCalendarPosition(datePosition: moment.Moment): void {
    this.changeCalendarPosition.emit(moment(datePosition).format('MM-DD-YYYY'));
    this.userInputStream.next(new UserInputStreamModel(this.inputValue$.getValue(), datePosition));
  }

  /** Вычисляет мин. и макс. даты */
  public mapMinMaxDates(inputValue: UserInputStreamModel): UserInputStreamModel {
    inputValue.minDate = this.minDate ? moment(this.minDate) : moment(`${MIN_YEAR}-01-01`);
    inputValue.maxDate = this.maxDate ? moment(this.maxDate) : moment(null);
    return inputValue;
  }

  /** Происходит на открытие/закрытие календаря */
  public onCalendarToggle(ev: Event): void {
    ev.stopPropagation();
    if (this.disabled) {
      return;
    }

    this.calendarState$.next(
      this.calendarState$.getValue() === CalendarWindowStates.close
        ? CalendarWindowStates.open
        : CalendarWindowStates.close
    );
    if (this.calendarState$.getValue() === CalendarWindowStates.open) {
      this.calculatePosition();
    } else {
      this.returnPosition();
    }
  }

  private calculatePosition(): void {
    this.isShowOptions = true;
    const el = this.elementRef.nativeElement;

    const selectBox = el.querySelector('.window-wrapper');
    const selectBoxLayout = selectBox.getClientRects()[0];
    selectBox.style.opacity = 1;
    const fitInWindow = window.innerHeight - selectBoxLayout?.top - 200;
    selectBox.style.top = `${window.scrollY + selectBoxLayout?.top + (fitInWindow < 0 ? fitInWindow - 10 : 0)}px`;
    selectBox.style.left = `${window.scrollX + selectBoxLayout?.left}px`;
    selectBox.style.width = `${selectBoxLayout?.width}px`;
    selectBox.style.position = 'fixed';
    selectBox.style.zIndex = 999;
  }

  /* Возвращает прежнее позиционирование */
  private returnPosition(): void {
    this.isShowOptions = false;
    const el = this.elementRef.nativeElement;
    const selectBox = el.querySelector('.window-wrapper');
    selectBox.style.top = 0;
    selectBox.style.opacity = 0;
    selectBox.style.left = 0;
    selectBox.style.width = 'auto';
    selectBox.style.position = 'inherit';
    selectBox.style.zIndex = 0;
  }
  /** Скрывает окно календаря */
  public hideCalendar(): void {
    this.calendarState$.next(CalendarWindowStates.close);
    this.returnPosition();
  }

  /** Закрывает окно календаря при клике вне его области */
  private closeCalendarWhenClickedOutside(): void {
    this.domEventService
      .confirmOwnershipOfClick(this.elementRef.nativeElement)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((isOwnClick: boolean) => {
        if (!isOwnClick) {
          this.hideCalendar();
        }
      });
  }

  /** Запускает итерацию, когда окно календарь проинициализирован */
  private firstIteration(): void {
    setTimeout(() => {
      this.userInputStream.next(new UserInputStreamModel(this.inputValue$.getValue()));
    });
  }
}
