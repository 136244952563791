import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { ConsiderationTypes } from '@appMain/calendar-consideration/enums/consideration-types.enum';
import { CalendarApiService } from '@appShared/api/calendar/calendar-api.service';
import { InputCalendarAnimations } from '@appShared/components/controls/input-calendar/components/input-calendar-base-component/input-calendar-window.animations';
import { HighlightedDateModel } from '@appShared/components/controls/input-calendar/models/highlighted-date.model';
import { CalendarPeriodParamsModel } from '@models/calendar/calendar.request/calendar-period-params.model';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CalendarDateColors } from '../../enums/calendar-date-colors.enum';
import { CalendarWindowStates } from '../../enums/calendar-window-states.enum';
import { DaysOfWeek } from '../../enums/days-of-week.enum';
import { CalendarDateCellModel, DisplayPositionModel } from '../../models/date-picker.model';
import { CalendarHolidayModel } from '../../models/holiday.model';
import { DatePickerService } from '../../services/date-picker.service';
import { HolidaysGet } from '../../store/holidays/holidays.actions';

/**
 * Компонент открывающего окна календаря
 */
@Component({
  selector: 'app-input-calendar-window',
  templateUrl: './input-calendar-window.component.html',
  styleUrls: ['./input-calendar-window.component.scss'],
  animations: InputCalendarAnimations,
})
export class InputCalendarWindowComponent extends Unsubscriber implements OnInit, OnChanges, OnDestroy {
  /** получать новые позиции окна календаря */
  @Input()
  calendarDisplayPosition: DisplayPositionModel;

  /** Использовать или нет темную тему */
  @Input()
  isBlackTheme: boolean;

  /** эмитер переключения позиции календаря */
  @Output()
  onChangeCalendarPosition = new EventEmitter<moment.Moment>();

  /** эмитер выбора даты в календаре */
  @Output()
  onDateClicked = new EventEmitter<moment.Moment>();

  /** Событие закрытия календаря */
  @Output()
  onCloseWindow = new EventEmitter<void>();

  /** Загружать расписание автономно */
  @Input()
  public shouldLoadSchedule = false;

  /** Тип заседания (ПМ/ППМ) для загрузки расписания */
  @Input()
  public considerationType: ConsiderationTypes | ConsiderationTypes[];

  /** В процессе */
  public isInProgress = false;

  /** Состояние окна календаря (открыт/закрыт) */
  private _calendarState: CalendarWindowStates = CalendarWindowStates.close;
  @Input()
  calendarState: CalendarWindowStates;

  @Input() isLoaderActive: boolean;

  /**Имя контролла */
  @Input()
  controlName: string;

  /** Даты для выделения */
  @Input()
  highlightedDates: HighlightedDateModel[];

  @Input()
  dayOfWeek: DaysOfWeek;

  @Input()
  showRecommendedDay: DaysOfWeek;

  public isBtnNowEnable = true;
  public inputDisplayPosition: DisplayPositionModel;
  public inputHighlightedDates: HighlightedDateModel[];
  public calendarDisplayPositionStream: Subject<DisplayPositionModel> = new Subject();
  public currentCalendarDisplayPosition$: Observable<CalendarDateCellModel[]>;
  public displayedYear: number;
  public displayedMonth: string;
  public numberOfDisplayedMonth: number;
  public isTodaySelected: boolean;
  public selectedDate: moment.Moment;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;

  constructor(
    private redux: ReduxService,
    private calendarService: DatePickerService,
    private calendarApi: CalendarApiService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToCalendarDisplayPosition();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { calendarDisplayPosition, highlightedDates, calendarState } = changes;
    if (calendarDisplayPosition) {
      this.inputDisplayPosition = calendarDisplayPosition.currentValue;
      this.calendarDisplayPositionStream.next(this.inputDisplayPosition);
    }
    if (highlightedDates) {
      this.inputHighlightedDates = highlightedDates.currentValue;
      this.calendarDisplayPositionStream.next(this.inputDisplayPosition);
    }
    if (calendarState) {
      this._calendarState = calendarState.currentValue;
      // Если нужно грузить расписание самостоятельно
      if (this.isCalendarOpened()) {
        this.loadSchedule(this.inputDisplayPosition.datePosition);
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  /** Переключает месяц */
  public onClickMonthYear(month: boolean, later: boolean): void {
    const offset = later ? 1 : -1;

    const newPosition = moment(`${this.displayedYear}${this.displayedMonth}01`, 'YYYYMMMDD').add(
      offset,
      month ? 'month' : 'year'
    );

    const switchRestriction = this.calendarService.isOutOfRestriction(newPosition, this.minDate, this.maxDate);
    if (switchRestriction) {
      return;
    }

    this.onChangeCalendarPosition.emit(newPosition);
    // Если нужно грузить расписание самостоятельно
    this.loadSchedule(newPosition);
  }

  /** пользователь выбрал дату */
  public onDateSelect(day: CalendarDateCellModel): void {
    if (day.isInactiveDay) {
      return;
    }

    const month = moment().month(day.month).format('MMM');
    this.selectedDate = moment(`${this.displayedYear}${month}${day.day}`, 'YYYYMMMDD');

    this.onDateClicked.emit(this.selectedDate);
  }

  /** пользователь выбрал сегодня */
  public onTodaySelect(): void {
    this.selectedDate = moment();
    this.onDateClicked.emit(this.selectedDate);
  }

  /** отметит сегодняшнюю дату */
  public selectToday(displayPosition: DisplayPositionModel): void {
    this.isTodaySelected = moment().isSame(displayPosition.currentDate, 'day');
  }

  /** проверка на необходимость подсветки дня */
  public isHighlitedDay(day: CalendarDateCellModel): boolean {
    return !day.isInactiveDay && !!day.highlightedColor;
  }

  /** определяем цвет подсветки в зависимости от текущих параметров */
  public getHighlightColor(day: CalendarDateCellModel): string {
    const selectedDate = moment(`${this.displayedYear}${this.displayedMonth}${day.day}`, 'YYYYMMMDD');

    const isWeekend = [DaysOfWeek.saturday, DaysOfWeek.sunday].includes(moment(selectedDate).day());
    const dayIsOnlyHoliday = !isWeekend && day.isHoliday && day.highlightedColor === CalendarDateColors.default;
    if (dayIsOnlyHoliday) {
      return null;
    }

    return !day.isInactiveDay ? day.highlightedColor : null;
  }

  /** подписаться на смену позиции календаря */
  private subscribeToCalendarDisplayPosition(): void {
    this.currentCalendarDisplayPosition$ = this.calendarDisplayPositionStream.asObservable().pipe(
      /** начать с текущего месяца */
      startWith({ currentDate: moment(null), datePosition: moment(), minDate: moment(null), maxDate: moment(null) }),
      tap((displayPosition: DisplayPositionModel) => {
        this.minDate = displayPosition.minDate;
        this.maxDate = displayPosition.maxDate;
        /** отметит сегодняшнюю дату */
        this.selectToday(displayPosition);
        /** указкать месяц и год текущей позиции календаря */
        this.setCurrentYearMonth(displayPosition);
        /** убрать кнопку Сегодня, если она выходит за разрешенный период */
        this.checkDisableBtnNow(displayPosition);
      }),
      /** подключить актуальный список праздников */
      switchMap((displayPosition: DisplayPositionModel) =>
        this.calendarService.subscribeToCurrentHolidays(displayPosition)
      ),
      /** выстроить ячейки текущей позиции календаря */
      map(([displayPosition, holidays]: [DisplayPositionModel, CalendarHolidayModel[]]) => {
        return this.mapToCalendarDateCells(displayPosition, holidays);
      })
    );
  }

  /** получить ячейки календаря */
  private mapToCalendarDateCells(
    displayPosition: DisplayPositionModel,
    holidays: CalendarHolidayModel[]
  ): CalendarDateCellModel[] {
    const firstMonday = this.calendarService.getLastMondayInLeftMonth(displayPosition.datePosition);
    const calendarDateCell: CalendarDateCellModel[] = [];

    for (let i = 0; i < 42; i++) {
      const day = moment(firstMonday).add(i, 'days');
      calendarDateCell.push({
        day: day.date(),
        month: day.month(),
        isToday: moment().isSame(day, 'day'),
        isInactiveDay: this.calendarService.isInactiveDay(displayPosition, day),
        isSelectedDay: day.isSame(displayPosition.currentDate, 'day'),
        isHoliday: this.calendarService.isHolyday(day, holidays),
        highlightedColor: this.calendarService.getHighlightedColor(day, this.inputHighlightedDates),
        highlightedFontWeight: this.calendarService.getHighlightedFontWeight(day, this.inputHighlightedDates),
        specialDay: moment(day).day() === this.dayOfWeek,
      });
    }

    return calendarDateCell;
  }

  /** задать текущий год и мясяц */
  private setCurrentYearMonth(displayPosition: DisplayPositionModel): void {
    const selectedYear = displayPosition.datePosition.year();

    if (selectedYear !== this.displayedYear) {
      this.redux.dispatchAction(new HolidaysGet(selectedYear));
    }

    this.displayedYear = selectedYear;
    this.displayedMonth = displayPosition.datePosition.format('MMM');
    this.numberOfDisplayedMonth = displayPosition.datePosition.month();
    this.cdr.detectChanges();
  }

  /** убрать кнопку Сегодня, если она выходит за разрешенный период */
  private checkDisableBtnNow(displayPosition: DisplayPositionModel): void {
    this.isBtnNowEnable = !(
      moment().isBefore(displayPosition.minDate, 'day') || moment().isAfter(displayPosition.maxDate, 'day')
    );
  }

  /* Загружает расписание / блокирует окно календаря */
  private loadSchedule(datePosition: moment.Moment): void {
    // Блокируем окно календаря
    this.isInProgress = true;

    this.getHighlightedDaysForMonth(datePosition)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((highlightedDays) => {
        const needLoadSchedule = this.shouldLoadSchedule && this.considerationType;
        this.inputHighlightedDates = needLoadSchedule ? highlightedDays : this.highlightedDates;
        this.calendarDisplayPositionStream.next(this.inputDisplayPosition);
        this.isInProgress = false;
      });
  }

  /* Получает подсвеченные дни для месяца выбранной даты */
  private getHighlightedDaysForMonth(selectedDate: moment.Moment): Observable<HighlightedDateModel[]> {
    const interval: CalendarPeriodParamsModel = {
      dateBegin: moment(selectedDate).startOf('month').startOf('week').format('YYYY-MM-DD'),
      dateEnd: moment(selectedDate).startOf('month').startOf('week').add(41, 'days').format('YYYY-MM-DD'),
    };

    return this.calendarApi.getMeetingDates(interval, this.considerationType).pipe(
      map((meetingDates) => this.calendarService.setMeetingDateColor(meetingDates, this.dayOfWeek)),
      map((meetingDates) =>
        this.showRecommendedDay
          ? this.calendarService.clearColorWhenWeekHasMeetings(meetingDates, this.dayOfWeek)
          : meetingDates
      )
    );
  }

  /* Проверяет, если календарь открыт */
  private isCalendarOpened(): boolean {
    return this._calendarState === CalendarWindowStates.open;
  }
}
