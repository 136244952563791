import { LdeEditorVersionCreatedMessageModel } from '@appDocuments/documents-composition/models/lde-editor-version-created.model';
import { ActualVersionPoint } from '@appDocuments/documents-route/models/actual-version-point.model';
import { PointApproveStatusInfo } from '@appDocuments/documents-route/models/point-approve-status-info.model';
import { CompositionDocumentToPrintResponseModel } from '@appShared/api/document-composition/models/document-composition.response/composition-document-to-print-response.model';
import { AssignmentUpdateModel } from '@models/delegating/assignment-update.model';
import { DelegatingExpiedModel } from '@models/delegating/delegating-expired.model';
import { DelegatingUpdateModel } from '@models/delegating/delegating-update.model';
import { LdeUpdateDocumentEventModel } from '@models/lde/lde-update-document-event.model';
import { NotificationCreatedEventModel } from '@models/notifications/notifications.response/notification-created-event.model';
import { NotificationExportEventModel } from '@models/notifications/notifications.response/notification-export-event.model';
import { SummaryAnalyticsUpdate } from '@appMain/analytics/summary-analytics/models/summary-analytics-update.model';
import { SocketChannel } from '../models/socket-channel';
import { DocumentEtalonResModel } from '@models/document/document-etalon.res.model';

export class SocketChannels {
  public static readonly ldeDocumentCreated: SocketChannel<LdeUpdateDocumentEventModel> = {
    address: '/user/ldeDocumentCreated',
  };

  public static readonly notificationsChanel: SocketChannel<NotificationCreatedEventModel> = {
    address: '/user/notifications',
  };

  public static readonly exportChanel: SocketChannel<NotificationExportEventModel> = {
    address: '/user/ldeExportEvents',
  };

  public static readonly documentToPrintChanel: SocketChannel<CompositionDocumentToPrintResponseModel> = {
    address: '/user/ldeMassPrintEvents',
  };

  public static readonly documentEditorVersionChanel: SocketChannel<LdeEditorVersionCreatedMessageModel> = {
    address: '/user/ldeEditorVersionEvents',
  };

  public static readonly assignmentUpdateChannel: SocketChannel<AssignmentUpdateModel> = {
    address: `/user/assignmentUpdate`,
  };

  public static delegatingExpiredChanel(id: number): SocketChannel<DelegatingExpiedModel> {
    return {
      address: `/user/delegatingExpired/${id}`,
    };
  }

  public static delegatingUpdateChannel(id: number): SocketChannel<DelegatingUpdateModel> {
    return {
      address: `/user/delegatingUpdate/${id}`,
    };
  }

  public static readonly summaryAnalyticsUpdate: SocketChannel<SummaryAnalyticsUpdate> = {
    address: `/summaryAnalyticsUpdate`,
  };

  public static readonly documentsActualRevisionsInfo: SocketChannel<ActualVersionPoint> = {
    address: `/user/documentsActualRevisionsInfo`,
  };

  public static readonly pointApproveStatusInfo: SocketChannel<PointApproveStatusInfo> = {
    address: `/user/pointApproveStatusInfo`,
  };
  public static readonly eaUpdateStatus: SocketChannel<DocumentEtalonResModel[]> = {
    address: `/eaUpdateStatus`,
  };
}
