import { Component, ElementRef, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConsiderationTypes } from '@appMain/calendar-consideration/enums/consideration-types.enum';
import { InputCalendarAnimations } from '@appShared/components/controls/input-calendar/components/input-calendar-base-component/input-calendar-window.animations';
import { InputCalendarBaseComponent } from '@appShared/components/controls/input-calendar/components/input-calendar-base-component/input-calendar.base';
import { UserInputStreamModel } from '@appShared/components/controls/input-calendar/models/date-picker.model';
import { DatePickerService } from '@appShared/components/controls/input-calendar/services/date-picker.service';
import { DomEventService } from '@appShared/services/dom-event.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DaysOfWeek } from '../../enums/days-of-week.enum';

/**
 * Компонент инпута с выпадающим календарём
 *
 * Загрузка расписания при открытии/смене месяца:
 *
 * <app-input-calendar
 *    [shouldLoadSchedule]="true"
 *    [considerationType]="considerationType"
 * ></app-input-calendar>
 *
 * @param shouldLoadSchedule - флаг загрузки расписания календарём при открытии/смене месяца
 * @param considerationType - тип рассмотрения для которого грузим расписание (ПМ/ППМ). Обязательный, если shouldLoadSchedule === true
 *
 *
 * Передача расписания в компонент из родительского:
 *
 * <app-input-calendar
 *    [highlightedDates]="highlightedDates$ | async"
 * ></app-input-calendar>
 *
 * @param highlightedDates - массив дат, которые должны быть подсвечены в календаре
 *
 *
 * Примечание: использовать инпут highlightedDates при автономной загрузке расписания не имеет никакого смысла.
 */
@Component({
  selector: 'app-input-calendar',
  templateUrl: './input-calendar.component.html',
  styleUrls: ['./input-calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCalendarComponent),
      multi: true,
    },
  ],
  animations: InputCalendarAnimations,
})
export class InputCalendarComponent extends InputCalendarBaseComponent implements OnInit {
  /** Скрыть кнопку очистки календаря */
  @Input()
  public hideClearButton: boolean;

  /** Загружать расписание автономно (на открытие/смену месяца) */
  @Input()
  public shouldLoadSchedule = false;

  /** Тип заседания, для которого грузим расписание */
  @Input()
  public considerationType: ConsiderationTypes | ConsiderationTypes[];

  @Input() isLoaderActive: boolean;

  @Input()
  dayOfWeek: DaysOfWeek;

  @Input()
  noClearDate: boolean;

  @Input()
  showRecommendedDay = true;

  @Input()
  height: string;

  public placeholder = 'дд.мм.гггг';
  public placeholderInput = this.placeholder;
  public isValidField = true;
  public isFieldWithValidDate: boolean;

  protected userInputStream: Subject<UserInputStreamModel> = new Subject();

  private lastStateDateString: string;

  constructor(private calendarService: DatePickerService, domEventService: DomEventService, elementRef: ElementRef) {
    super(domEventService, elementRef);
  }

  ngOnInit(): void {
    this.subscribeToUserInputStream();
  }

  /* Слушает поток пользовательского ввода */
  private subscribeToUserInputStream(): void {
    this.userInputStream
      .asObservable()
      .pipe(
        map((inputValue: UserInputStreamModel) => this.mapMinMaxDates(inputValue)),
        map((inputValue: UserInputStreamModel) => this.addSeparatesAfterEnteringSymbol(inputValue)),
        map((inputValue: UserInputStreamModel) => this.calendarService.addZeroInsteadOfUser(inputValue)),
        map((inputValue: UserInputStreamModel) => this.calendarService.checkInputString(inputValue)),
        map((inputValue: UserInputStreamModel) =>
          this.calendarService.clearOutCurrentDate(inputValue, this.noClearDate)
        ),
        map((inputValue: UserInputStreamModel) => this.calendarService.movePositionToPermittedPeriod(inputValue)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((inputValue: UserInputStreamModel) => {
        this.changePlaceholder(inputValue.inputString);
        this.changeInputField(inputValue.inputString);
        this.isValidField = inputValue.isValidInput && inputValue.isValidPeriod;
        this.isFieldWithValidDate = inputValue.currentDate.isValid();
        this.onChange(inputValue.currentDate.toISOString(this.isShowLocalTimeZone));
        this.calendarDisplayPosition = {
          currentDate: inputValue.currentDate,
          datePosition: inputValue.datePosition,
          minDate: inputValue.minDate,
          maxDate: inputValue.maxDate,
        };
      });
  }

  /** Устанавливает сегодняшнюю дату в поле */
  public setNowDate(): void {
    this.onTouched();
    if (this.minDate && moment(this.minDate).isAfter(moment(), 'day')) {
      this.userInputStream.next(new UserInputStreamModel(moment(this.minDate).format(this.dateFormat)));
      return;
    }

    this.userInputStream.next(new UserInputStreamModel(moment().format(this.dateFormat)));
  }

  /** Происходит на ввод в поле */
  public onInputChange(inputString: string): void {
    this.onTouched();
    this.userInputStream.next(new UserInputStreamModel(inputString));
  }

  /** Происходит при потере фокуса полем */
  public onInputBlur(): void {
    if (!this.isFieldWithValidDate) {
      this.resetDate();
    }
    this.onTouched();
  }

  /**
   * Происходит при выборе дня в календаре
   *
   * @param date - выбранный день
   */
  public onDateSelected(date: moment.Moment): void {
    this.hideCalendar();
    super.onDateSelected(date);
    this.onTouched();
  }

  /* Добавляет разделители (точки) при вводе цифр */
  private addSeparatesAfterEnteringSymbol(inputValue: UserInputStreamModel): UserInputStreamModel {
    inputValue.inputString = this.calendarService.insertDateSeparator(inputValue.inputString, this.lastStateDateString);
    this.lastStateDateString = inputValue.inputString;
    return inputValue;
  }

  /* Менеят подсказку при вводе */
  private changePlaceholder(inputString: string): void {
    this.placeholderInput = inputString + this.placeholder.slice(inputString.length);
  }

  /* Меняет значение в поле */
  private changeInputField(inputString: string): void {
    this.inputValue$.next(inputString);
    this.isShowOptions = false;
  }
}
