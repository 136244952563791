export enum WorkStatus {
  inProgress = 'Проекты в работе',
  allReviewed = 'Отработанные проекты',
  registration = 'Регистрация',
}

export enum WorkStatusKeys {
  inProgress = 'inProgress',
  allReviewed = 'allReviewed',
  registration = 'registration',
}

export type WorkStatusType = WorkStatusKeys.inProgress | WorkStatusKeys.allReviewed | WorkStatusKeys.registration;
