import { Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@environments/environment.prod';
import { tap } from 'rxjs/operators';
import { Urls } from '@appCore/urls/urls';

const baseUrl = environment.baseUrl;

@Injectable()
export class HttpCacheInterceptor implements HttpInterceptor {
  private cache = new Map<string, HttpResponse<any>>();
  private endpointsToCache = [Urls.getEmployeePositionsBaseUrl(), Urls.getEmployeeListBaseUrl()];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.endpointsToCache.some((item) => request.url.includes(item))) {
      const cachedResponse = this.cache.get(request.url);
      if (cachedResponse) {
        return of(cachedResponse);
      }
      return next.handle(request).pipe(
        tap((response) => {
          if (response instanceof HttpResponse && response.status === HttpStatusCode.Ok) {
            this.cache.set(request.url, response);
          }
        })
      );
    }
    return next.handle(request);
  }
}
export const HttpCacheInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpCacheInterceptor,
  multi: true,
};
